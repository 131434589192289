%svg-common {
	background: url("../assets/images/sprite.svg") no-repeat;
}

.svg-AlertRed28 {
	@extend %svg-common;
	background-position: 63.66279069767442% 44.927536231884055%;
}

.svg-AlertRed28-dims {
	width: 28px;
	height: 25px;
}

.svg-accountPairingSettings40 {
	@extend %svg-common;
	background-position: 98.49397590361446% 36.25730994152047%;
}

.svg-accountPairingSettings40-dims {
	width: 40px;
	height: 28px;
}

.svg-avatar47 {
	@extend %svg-common;
	background-position: 67.07692307692308% 56.34674922600619%;
}

.svg-avatar47-dims {
	width: 47px;
	height: 47px;
}

.svg-backButton32 {
	@extend %svg-common;
	background-position: 86.6096866096866% 67.15976331360947%;
}

.svg-backButton32-dims {
	width: 21px;
	height: 32px;
}

.svg-backButton46 {
	@extend %svg-common;
	background-position: 80.80229226361031% 78.44311377245509%;
}

.svg-backButton46-dims {
	width: 23px;
	height: 36px;
}

.svg-capture46 {
	@extend %svg-common;
	background-position: 0 79.8780487804878%;
}

.svg-capture46-dims {
	width: 46px;
	height: 42px;
}

.svg-company28 {
	@extend %svg-common;
	background-position: 19.767441860465116% 88.88888888888889%;
}

.svg-company28-dims {
	width: 28px;
	height: 28px;
}

.svg-currentCases38 {
	@extend %svg-common;
	background-position: 61.67664670658683% 78.91566265060241%;
}

.svg-currentCases38-dims {
	width: 38px;
	height: 38px;
}

.svg-doctor28 {
	@extend %svg-common;
	background-position: 27.906976744186046% 88.88888888888889%;
}

.svg-doctor28-dims {
	width: 28px;
	height: 28px;
}

.svg-downloadIcon34 {
	@extend %svg-common;
	background-position: 0 89.94082840236686%;
}

.svg-downloadIcon34-dims {
	width: 34px;
	height: 32px;
}

.svg-downloadScans22 {
	@extend %svg-common;
	background-position: 87.14285714285714% 75.07163323782235%;
}

.svg-downloadScans22-dims {
	width: 22px;
	height: 21px;
}

.svg-downloadScansDark22 {
	@extend %svg-common;
	background-position: 57.42857142857143% 87.10601719197707%;
}

.svg-downloadScansDark22-dims {
	width: 22px;
	height: 21px;
}

.svg-downloadScreenshots34 {
	@extend %svg-common;
	background-position: 10.059171597633137% 89.41176470588235%;
}

.svg-downloadScreenshots34-dims {
	width: 34px;
	height: 30px;
}

.svg-downloadSuccess24 {
	@extend %svg-common;
	background-position: 93.96551724137932% 80.45325779036827%;
}

.svg-downloadSuccess24-dims {
	width: 24px;
	height: 17px;
}

.svg-downloadsResources44 {
	@extend %svg-common;
	background-position: 99.6951219512195% 13.636363636363637%;
}

.svg-downloadsResources44-dims {
	width: 44px;
	height: 40px;
}

.svg-education52 {
	@extend %svg-common;
	background-position: 83.125% 29.23076923076923%;
}

.svg-education52-dims {
	width: 52px;
	height: 45px;
}

.svg-exportGallery34 {
	@extend %svg-common;
	background-position: 35.632183908045974% 88.88888888888889%;
}

.svg-exportGallery34-dims {
	width: 24px;
	height: 28px;
}

.svg-exportSettings34 {
	@extend %svg-common;
	background-position: 54.86725663716814% 45.18950437317784%;
}

.svg-exportSettings34-dims {
	width: 33px;
	height: 27px;
}

.svg-exportSettings50 {
	@extend %svg-common;
	background-position: 82.6086956521739% 56.666666666666664%;
}

.svg-exportSettings50-dims {
	width: 50px;
	height: 40px;
}

.svg-externalLinkAlt22 {
	@extend %svg-common;
	background-position: 9.428571428571429% 96.55172413793103%;
}

.svg-externalLinkAlt22-dims {
	width: 22px;
	height: 22px;
}

.svg-eyeIcon28 {
	@extend %svg-common;
	background-position: 43.02325581395349% 88.88888888888889%;
}

.svg-eyeIcon28-dims {
	width: 28px;
	height: 28px;
}

.svg-home46 {
	@extend %svg-common;
	background-position: 73.05389221556887% 78.91566265060241%;
}

.svg-home46-dims {
	width: 38px;
	height: 38px;
}

.svg-icn_pen50 {
	@extend %svg-common;
	background-position: 26.54320987654321% 41.717791411042946%;
}

.svg-icn_pen50-dims {
	width: 48px;
	height: 44px;
}

.svg-iconWechat61 {
	@extend %svg-common;
	background-position: 50.48231511254019% 56.875%;
}

.svg-iconWechat61-dims {
	width: 61px;
	height: 50px;
}

.svg-info96 {
	@extend %svg-common;
	background-position: 0 0;
}

.svg-info96-dims {
	width: 96px;
	height: 96px;
}

.svg-invisalign40 {
	@extend %svg-common;
	background-position: 98.49397590361446% 42.57703081232493%;
}

.svg-invisalign40-dims {
	width: 40px;
	height: 13px;
}

.svg-iteroHeaderLogo61 {
	@extend %svg-common;
	background-position: 85.53054662379421% 0;
}

.svg-iteroHeaderLogo61-dims {
	width: 61px;
	height: 61px;
}

.svg-iteroLoginAsLogo86 {
	@extend %svg-common;
	background-position: 0 33.80281690140845%;
}

.svg-iteroLoginAsLogo86-dims {
	width: 86px;
	height: 86px;
}

.svg-iteroLoginLogo86 {
	@extend %svg-common;
	background-position: 30.06993006993007% 29.09090909090909%;
}

.svg-iteroLoginLogo86-dims {
	width: 86px;
	height: 40px;
}

.svg-localization48 {
	@extend %svg-common;
	background-position: 41.358024691358025% 41.21212121212121%;
}

.svg-localization48-dims {
	width: 48px;
	height: 40px;
}

.svg-loginSettings40 {
	@extend %svg-common;
	background-position: 95.05813953488372% 50%;
}

.svg-loginSettings40-dims {
	width: 28px;
	height: 40px;
}

.svg-magnifingGlass26 {
	@extend %svg-common;
	background-position: 50.720461095100866% 88.37209302325581%;
}

.svg-magnifingGlass26-dims {
	width: 25px;
	height: 26px;
}

.svg-messages42 {
	@extend %svg-common;
	background-position: 99.0909090909091% 25.6797583081571%;
}

.svg-messages42-dims {
	width: 42px;
	height: 39px;
}

.svg-messages80 {
	@extend %svg-common;
	background-position: 63.6986301369863% 0;
}

.svg-messages80-dims {
	width: 80px;
	height: 75px;
}

.svg-messagesAlert22 {
	@extend %svg-common;
	background-position: 63.714285714285715% 86.85714285714286%;
}

.svg-messagesAlert22-dims {
	width: 22px;
	height: 20px;
}

.svg-more-icon22 {
	@extend %svg-common;
	background-position: 97.26027397260275% 47.00854700854701%;
}

.svg-more-icon22-dims {
	width: 7px;
	height: 19px;
}

.svg-notificationsSettings40 {
	@extend %svg-common;
	background-position: 98.49397590361446% 61.933534743202415%;
}

.svg-notificationsSettings40-dims {
	width: 40px;
	height: 39px;
}

.svg-orders80 {
	@extend %svg-common;
	background-position: 62.41610738255034% 25.862068965517242%;
}

.svg-orders80-dims {
	width: 74px;
	height: 80px;
}

.svg-paperclip24 {
	@extend %svg-common;
	background-position: 75.07082152974505% 86.6096866096866%;
}

.svg-paperclip24-dims {
	width: 19px;
	height: 19px;
}

.svg-patientEdit40 {
	@extend %svg-common;
	background-position: 97.61194029850746% 73.93939393939394%;
}

.svg-patientEdit40-dims {
	width: 37px;
	height: 40px;
}

.svg-patients80 {
	@extend %svg-common;
	background-position: 0 62.758620689655174%;
}

.svg-patients80-dims {
	width: 77px;
	height: 80px;
}

.svg-penIcon20 {
	@extend %svg-common;
	background-position: 69.60227272727273% 86.85714285714286%;
}

.svg-penIcon20-dims {
	width: 20px;
	height: 20px;
}

.svg-pendingCases40 {
	@extend %svg-common;
	background-position: 13.855421686746988% 79.39393939393939%;
}

.svg-pendingCases40-dims {
	width: 40px;
	height: 40px;
}

.svg-plus-icon22 {
	@extend %svg-common;
	background-position: 98.8857938718663% 51.5406162464986%;
}

.svg-plus-icon22-dims {
	width: 13px;
	height: 13px;
}

.svg-practiceSettings45 {
	@extend %svg-common;
	background-position: 0 100%;
}

.svg-practiceSettings45-dims {
	width: 33px;
	height: 34px;
}

.svg-printRx22 {
	@extend %svg-common;
	background-position: 15.714285714285714% 96.27507163323783%;
}

.svg-printRx22-dims {
	width: 22px;
	height: 21px;
}

.svg-printRxDark22 {
	@extend %svg-common;
	background-position: 22% 96.27507163323783%;
}

.svg-printRxDark22-dims {
	width: 22px;
	height: 21px;
}

.svg-printSuccess24 {
	@extend %svg-common;
	background-position: 45.11494252873563% 65.53672316384181%;
}

.svg-printSuccess24-dims {
	width: 24px;
	height: 16px;
}

.svg-privacy100 {
	@extend %svg-common;
	background-position: 34.04255319148936% 0;
}

.svg-privacy100-dims {
	width: 90px;
	height: 90px;
}

.svg-rx38 {
	@extend %svg-common;
	background-position: 79.64071856287426% 67.55952380952381%;
}

.svg-rx38-dims {
	width: 38px;
	height: 34px;
}

.svg-rx52 {
	@extend %svg-common;
	background-position: 83.125% 43.343653250774%;
}

.svg-rx52-dims {
	width: 52px;
	height: 47px;
}

.svg-rx80 {
	@extend %svg-common;
	background-position: 26.36986301369863% 61.07382550335571%;
}

.svg-rx80-dims {
	width: 80px;
	height: 72px;
}

.svg-rxSettings40 {
	@extend %svg-common;
	background-position: 25.903614457831324% 79.39393939393939%;
}

.svg-rxSettings40-dims {
	width: 40px;
	height: 40px;
}

.svg-settings45 {
	@extend %svg-common;
	background-position: 100% 0;
}

.svg-settings45-dims {
	width: 45px;
	height: 45px;
}

.svg-systemInformation40 {
	@extend %svg-common;
	background-position: 37.95180722891566% 79.39393939393939%;
}

.svg-systemInformation40-dims {
	width: 40px;
	height: 40px;
}

.svg-totalCases40 {
	@extend %svg-common;
	background-position: 50% 79.39393939393939%;
}

.svg-totalCases40-dims {
	width: 40px;
	height: 40px;
}

.svg-trashIcon21 {
	@extend %svg-common;
	background-position: 69.77401129943503% 44.412607449856736%;
}

.svg-trashIcon21-dims {
	width: 18px;
	height: 21px;
}

.svg-viewer61 {
	@extend %svg-common;
	background-position: 85.53054662379421% 18.154761904761905%;
}

.svg-viewer61-dims {
	width: 61px;
	height: 34px;
}

