$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
$fa-font-path: '~font-awesome/fonts';

@import '/node_modules/ngx-toastr/toastr.css';
@import "fonts";
@import "/node_modules/font-awesome/scss/font-awesome";
@import "variables";
@import "mixins";
@import "/node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/theme";
@import "bootstrapCustomization";
@import "shared";
@import "spinner";
@import "sprite";
@import "bs-datepicker.4.0.0";